<!--物联网统计数据-->
<template>
  <common-container  title="物联网故障数据统计图" class="left-middle">
    <div class="pie" id="main1" style="height: 100%;width: 100%;"></div>
  </common-container>
</template>
<script>
import {
  getAllIotTypeevent,
  getEventListCountByCondition
} from "A/wlw";

let echarts = require('echarts')
// 引入饼状图组件
require('echarts/lib/chart/pie')
// 引入提示框和title组件
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')

import CommonContainer from "@/views/lotBigData/components/commonContainer/Index";
import {getCache} from "U/index";
import {getUserByUserid} from "A/login";
export default {
  name:'LeftMiddle',
  components: {CommonContainer},
  props: {
    user:{
      default:null
    },
    SelectedArea:{
      default:""
    },
    SelectedPlace:{
      default:""
    },
    SelectedLiftyear:{
      default:""
    },
    SelectedDevicemodelid:{
      default:""
    },
  },
  data() {
    return {
      areacode:'',
      myChart:null,
    }
  },
  created(){
    this.initOptions();
  },
  mounted(){
    const resize = ()=>{
      setTimeout(()=>{
        if(this.myChart){this.myChart.resize()}
      })
    }
    window.addEventListener('resize',resize)
    this.$once('hook:beforeDestroy',()=>{
      window.removeEventListener('resize',resize)
    })
  },
  watch:{
    'SelectedArea':function (){
      this.initOptions()
    },
    'SelectedPlace':function (){
      this.initOptions()
    },
    'SelectedLiftyear':function (){
      this.initOptions()
    },
    'SelectedDevicemodelid':function (){
      this.initOptions()
    },
  },
  methods:{
    initOptions(){
      const p_option={
        title : {
          // text: '故障数据图',//主标题
          x:'center',//x轴方向对齐方式
        },
        tooltip : {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          // type: 'scroll',
          // orient: 'vertical',
          textStyle: { //图例文字的样式
            color: '#999999',
          },
          width:'100%',
          itemWidth: 12,             // 图例图形宽度
          // itemHeight: 10,
          orient: 'horizontal',     // 图例横向排布
          bottom: 'bottom',
          top:'70%',
          data: ['安全回路断路','关门故障','开门故障','轿厢在开锁区域外停止','轿厢意外移动','电动机运转时间限制器动作','楼层位置丢失','困人', '电梯速度异常']
          // data: ['安全回路断路','关门故障','开门故障','轿厢在开锁区域外停止','轿厢意外移动','电动机运转时间限制器动作','楼层位置丢失','困人','电源故障','电梯通信故障',
          //   '门锁故障','制动系统故障','控制系统故障','驱动系统故障', '电梯速度异常', '其他阻止电梯再启动的故障']
        },
        series : [
          {
            name: '故障类型',
            type: 'pie',
            radius : '45%',
            center: ['43%', '35%'],
            label: {
              // normal: {
                color: '#999999',
                show: true,
                position: 'left'
              // },
            },
            labelLine: {
              // normal: {
                show: true
              // }
            },
            data: [
              {value:'', name:'安全回路断路'},
              {value:'', name:'关门故障'},
              {value:'', name:'开门故障'},
              {value:'', name:'轿厢在开锁区域外停止'},
              {value:'', name:'轿厢意外移动'},
              {value:'', name:'电动机运转时间限制器动作'},
              {value:'', name:'楼层位置丢失'},
              {value:'', name:'困人'},
              {value:'', name:'电梯反复开关门'},
              // {value:'', name:'电源故障'},
              // {value:'', name:'电梯通信故障'},
              // {value:'', name:'门锁故障'},
              // {value:'', name:'制动系统故障'},
              // {value:'', name:'控制系统故障'},
              // {value:'', name:'驱动系统故障'},
              {value:'', name:'电梯速度异常'},
              {value:'', name:'其他故障'},

            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      let logininfo = getCache('logininfo', true);
      if(logininfo) {
        this.usertype = logininfo.usertype;
      }
          if(this.usertype=='0'){
            let params={
              areacode: this.SelectedArea=="0"?"":this.SelectedArea,
              placeid: this.SelectedPlace=="0"?"":this.SelectedPlace,
              liftyear: this.SelectedLiftyear=="0"?"":this.SelectedLiftyear,
              terminaltypeid: this.SelectedDevicemodelid=="0"?"":this.SelectedDevicemodelid,
              eventtype: 1
            }
            getEventListCountByCondition(params).then(res=>{
              if (res && res.returncode == '0') {
                for(var i=0;i<res.item.length;i++) {
                  for (var j = 0; j < 10; j++) {
                    if (res.item[i].eventname == p_option.series[0].data[j].name) {
                      p_option.series[0].data[j].value = res.item[i].num
                    }
                  }
                }
              }
            })
          }else{
            let logininfos = getCache('logininfo', true);
            if(logininfos) {
              this.areacode=logininfos.areacode;
            }
            let params={
              areacode: this.SelectedArea=="0"?"":this.SelectedArea,
              placeid: this.SelectedPlace=="0"?"":this.SelectedPlace,
              liftyear: this.SelectedLiftyear=="0"?"":this.SelectedLiftyear,
              terminaltypeid: this.SelectedDevicemodelid=="0"?"":this.SelectedDevicemodelid,
              eventtype:'1'
            }
            getAllIotTypeevent(params).then(res => {
              if (res && res.returncode == '0') {
                for(var i=0;i<res.item.length;i++){
                  for(var j=0;j<10;j++) {
                    if (res.item[i].eventname == p_option.series[0].data[j].name) {
                      p_option.series[0].data[j].value = res.item[i].num
                    }
                  }
                }
              }
            })
          }
      //   }
      // })
      // this.initData(p_option)
      setTimeout(() =>{
        this.initData(p_option)
      },1500);
    },
    initData(p_option) {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = echarts.init(document.getElementById('main1'));
      // 绘制图表
      // console.log(p_option.series[0].data.value)
      this.myChart.hideLoading();
      // console.log(p_option.series)
      this.myChart.setOption(p_option);
    },
  }
}
</script>
<style lang="scss" scoped>
.pie{
  pointer-events: auto;
  //margin-left: 5%;
}
.left-middle{
  float: left;
  width: 100%!important;
  height: 48%;
  overflow: hidden;
}
</style>

<!--<template>-->
<!--  <common-container  title="物联网统计数据" class="left-middle">-->
<!--    <div class="padding height-100">-->
<!--      <a-row>-->
<!--        <a-col :span="12">-->
<!--          <statistic title="累计运行时间" :value="datas.accumulative_run_time" unit="分"></statistic>-->
<!--        </a-col>-->
<!--        <a-col :span="12">-->
<!--          <statistic title="累计运行次数" :value="datas.accumulative_run_num" unit="次"></statistic>-->
<!--        </a-col>-->
<!--      </a-row>-->
<!--      <a-row>-->
<!--        <a-col :span="12">-->
<!--          <statistic title="当日开关门次数" :value="datas.today_door_num" unit="次"></statistic>-->
<!--        </a-col>-->
<!--        <a-col :span="12">-->
<!--          <statistic title="累计开关门次数" :value="datas.accumulative_door_num" unit="次"></statistic>-->
<!--        </a-col>-->
<!--      </a-row>-->
<!--      <a-row>-->
<!--        <a-col :span="12">-->
<!--          <statistic title="当日人流量" :value="datas.today_passenger_num" unit="人次"></statistic>-->
<!--        </a-col>-->
<!--        <a-col :span="12">-->
<!--          <statistic title="累计人流量" :value="datas.accumulative_passenger_num" unit="人次"></statistic>-->
<!--        </a-col>-->
<!--      </a-row>-->
<!--    </div>-->
<!--    <div class="padding height-100">-->
<!--    <a-row>-->
<!--      <a-col :span="12">-->
<!--        <statistic title="故障总数" :value="faultCount" unit="次"></statistic>-->
<!--      </a-col>-->
<!--      <a-col :span="12">-->
<!--        <statistic title="AI事件总数" :value="aiEventCount" unit="次"></statistic>-->
<!--      </a-col>-->
<!--    </a-row>-->
<!--    <a-row>-->
<!--      <a-col :span="12">-->
<!--        <statistic title="报警总数" :value="alarmCount" unit="次"></statistic>-->
<!--      </a-col>-->
<!--      <a-col :span="12">-->
<!--        <statistic title="电梯事件总数" :value="liftEventCount" unit="次"></statistic>-->
<!--      </a-col>-->
<!--    </a-row>-->
<!--    <a-row>-->
<!--      <a-col :span="12">-->
<!--        <statistic title="物联网终端事件总数" :value="iotEventCount" unit="次"></statistic>-->
<!--      </a-col>-->
<!--&lt;!&ndash;      <a-col :span="12">&ndash;&gt;-->
<!--&lt;!&ndash;        <statistic title="累计人流量" :value="datas.accumulative_passenger_num" unit="人次"></statistic>&ndash;&gt;-->
<!--&lt;!&ndash;      </a-col>&ndash;&gt;-->
<!--    </a-row>-->
<!--    </div>-->
<!--  </common-container>-->
<!--</template>-->

<!--<script>-->
<!--  import CommonContainer from "@/views/lotBigData/components/commonContainer/Index";-->
<!--  import Statistic from "../components/statistic/Index";-->
<!--  import { mapState } from 'vuex'-->
<!--  import {getEventrecordListByCondition, getIotDateevent,getEventrecordCountByCondition} from "@/api/wlw";-->
<!--  export default{-->
<!--    name:'LeftMiddle',-->
<!--    components: {CommonContainer,Statistic},-->
<!--    computed: {-->
<!--      ...mapState({-->
<!--        areacode: state => state.main.areacode,-->
<!--        fontSize: state => state.main.fontSize,-->
<!--      })-->
<!--    },-->
<!--    props: {-->
<!--      datas: {-->
<!--        type: Object,-->
<!--        default: ()=>({})-->
<!--      },-->
<!--    },-->
<!--    data() {-->
<!--      return {-->
<!--        faultCount:'',-->
<!--        aiEventCount:'',-->
<!--        alarmCount:'',-->
<!--        liftEventCount:'',-->
<!--        iotEventCount:'',-->
<!--        allData: {-->
<!--          // alarmCount:[],//报警次数-->
<!--          // chokeGateCount:[],//阻门事件次数-->
<!--          // trapCount:[],//困人次数-->
<!--          // batterCarCount:[],//电瓶车事件次数-->
<!--          // faultCount:[]//故障次数-->
<!--          faultCount:[],//故障次数-->
<!--          aiEventCount:[],//AI事件次数-->
<!--          alarmCount:[],//报警次数-->
<!--          liftEventCount:[],//电梯事件次数-->
<!--          iotEventCount:[],//物联网终端事件次数-->
<!--        },-->
<!--        // timer:null-->
<!--      }-->
<!--    },-->
<!--    mounted () {-->
<!--      // this.getIotDate()-->
<!--      // this.$once('hook:beforeDestroy',()=>{-->
<!--      //   clearTimeout(this.timer)-->
<!--      // })-->
<!--      this.getFaultCount();-->
<!--      this.getAiEventCount();-->
<!--      this.getAlarmCount();-->
<!--      this.getLiftEventCount();-->
<!--      this.getIotEventCount();-->
<!--    },-->
<!--    methods: {-->
<!--      // getIotDate() {-->
<!--      //   getIotDateevent({-->
<!--      //     areacode:this.areacode-->
<!--      //   }).then(res=>{-->
<!--      //     if(res.returncode==="0"){-->
<!--      //       this.allData = res.item-->
<!--      //     }-->
<!--      //     clearTimeout(this.timer)-->
<!--      //     this.timer = setTimeout(this.getIotDate,1000*60*5)-->
<!--      //   })-->
<!--      // }-->
<!--      // init() {-->
<!--      //   this.getFaultCount();-->
<!--      // },-->
<!--      getFaultCount() {-->
<!--        let params = {-->
<!--          eventtype: '1',-->
<!--        }-->
<!--        getEventrecordCountByCondition(params).then(res => {-->
<!--          if (res && res.returncode == '0') {-->
<!--            this.faultCount = res.count;-->
<!--          }-->
<!--        })-->
<!--      },-->
<!--      getAiEventCount() {-->
<!--        let params = {-->
<!--          eventtype: '3',-->
<!--        }-->
<!--        getEventrecordCountByCondition(params).then(res => {-->
<!--          if (res && res.returncode == '0') {-->
<!--            this.aiEventCount = res.count;-->
<!--          }-->
<!--        })-->
<!--      },-->
<!--      getAlarmCount() {-->
<!--        let params = {-->
<!--          eventtype: '2',-->
<!--        }-->
<!--        getEventrecordCountByCondition(params).then(res => {-->
<!--          if (res && res.returncode == '0') {-->
<!--            this.alarmCount = res.count;-->
<!--          }-->
<!--        })-->
<!--      },-->

<!--      getLiftEventCount() {-->
<!--        let params = {-->
<!--          eventtype: '4',-->
<!--        }-->
<!--        getEventrecordCountByCondition(params).then(res => {-->
<!--          if (res && res.returncode == '0') {-->
<!--            this.liftEventCount = res.count;-->
<!--          }-->
<!--        })-->
<!--      },-->
<!--      getIotEventCount() {-->
<!--        let params = {-->
<!--          eventtype: '5',-->
<!--        }-->
<!--        getEventrecordCountByCondition(params).then(res => {-->
<!--          if (res && res.returncode == '0') {-->
<!--            this.iotEventCount = res.count;-->
<!--          }-->
<!--        })-->
<!--      },-->
<!--    },-->
<!--  }-->
<!--</script>-->

<!--<style lang="scss" scoped>-->
<!--  .left-middle{-->
<!--    width: 100%;-->
<!--    height: 28%;-->
<!--    overflow: hidden;-->
<!--  }-->
<!--  #sjtj{-->
<!--    width: 100%;-->
<!--    height: 100%;-->
<!--  }-->
<!--  .padding{-->
<!--    padding: 8px;-->
<!--    height: 100%;-->
<!--  }-->
<!--  .height-100{-->
<!--    height: 100%;-->
<!--    >.ant-row{-->
<!--      height: 20%;-->
<!--    }-->
<!--  }-->
<!--  .sp-cl{-->
<!--    color: #2C9F85;-->
<!--  }-->
<!--</style>-->