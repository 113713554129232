<template>
  <common-container title="实时报警数据统计" class="right-bottom">
    <vueInfiniteAutoScroll ref="scroller" :data="lists" :option="{newFirst:true}" infinite-scroll-immediate-check="true" class="scroller-wrap">
      <template slot-scope="props">
        <div class="flex-box">
          <span class="name-class" v-text="props.item.name"></span>
          <span class="text-gray" v-text="props.item.type"></span>
          <span class="text-gray min-105" v-text="props.item.time"></span>
        </div>
      </template>
    </vueInfiniteAutoScroll>
  </common-container>
</template>

<script>
  import vueInfiniteAutoScroll from 'vue-infinite-auto-scroll'
  import CommonContainer from "@/views/lotBigData/components/commonContainer/Index";
  import moment from 'moment';
  import { getEventrecordListByCondition } from "@/api/wlw";
  export default{
    name:'RightBottom',
    components: {CommonContainer,vueInfiniteAutoScroll},
    props:{
      SelectedArea:{
        default:""
      },
      SelectedPlace:{
        default:""
      },
      SelectedLiftyear:{
        default:""
      },
      SelectedDevicemodelid:{
        default:""
      },
    },
    data() {
      return {
        lists:[],
        timer:null
      }
    },
    mounted () {
      this.getDatas()
      this.$once('hook:beforeDestroy',()=>{
        clearTimeout(this.timer)
      })
    },
    watch:{
      'SelectedArea':function (){
        this.getDatas()
      },
      'SelectedPlace':function (){
        this.getDatas()
      },
      'SelectedLiftyear':function (){
        this.getDatas()
      },
      'SelectedDevicemodelid':function (){
        this.getDatas()
      },
    },
    methods: {
      getDatas() {
        getEventrecordListByCondition({
          areacode: this.SelectedArea=="0"?"":this.SelectedArea,
          placeid: this.SelectedPlace=="0"?"":this.SelectedPlace,
          liftyear: this.SelectedLiftyear=="0"?"":this.SelectedLiftyear,
          terminaltypeid: this.SelectedDevicemodelid=="0"?"":this.SelectedDevicemodelid,
          eventtype:"2",
          pageno:"1",
          pagesize:"50"
        }).then(res=>{
          const {returncode,item} = res
          if(returncode==="0"){
            this.lists = item.map(v=>({
              name:v.adress,
              type:`${v.eventname}${v.thirdeventname?'('+v.thirdeventname+')':''}`,
              time:moment(v.eventstarttime, 'YYYYMMDDHHmmss').format("YYYY.MM.DD HH:mm:ss")
            }))
          }
          clearTimeout(this.timer)
          this.timer = setTimeout(this.getDatas,1000*60*5)
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  //.right-top{
  //  width: 100%;
  //  height: 100%;
  //}
  .right-bottom{
    margin-left: 5%;
    width: 100%;
    height: 60%;
    overflow: hidden;
  }
  .scroller-wrap{
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .flex-box{
    display: flex;
    justify-content: space-between;
    line-height: 2.4em;
    font-size: 0.85em;
    color: #8bb9f9;
  }
  .text-gray{
    color: #566e90;
  }
  .name-class{
    width: 42%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .min-105{
    min-width: 105px;
  }
</style>