<template>
  <div class="common-container">
    <div class="title">{{title}}</div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default{
    name:"commonContainer",
    computed: {
      ...mapState({
        fontSize: state => state.main.fontSize,
      })
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      // color: {
      //   type: String,
      //   default: '#11315B'
      // },
    },
  }
</script>

<style lang="scss" scoped>
  .common-container{
    width:100%;
  }
  .content{
    width: 100%;
    height: calc(100% - 30px);
  }
  .title{
    position: relative;
    display: inline-block;
    padding: 5px 10px;
    font-size: 16px;
    height: 26px;
    line-height: 16px;
    color: #fff;
    background: url('~@/assets/image/lot/title-duan.png') no-repeat;
    background-size: 100% 100%;
  }
</style>