<template>
  <div class="linecharts" :id="id" style="height: 100%;width: 100%;"></div>
</template>

<script>
  import { mapState } from 'vuex'
  export default{
    name:"lineCharts",
    props: {
      id: {
        type: String,
        default: ''
      },
      lists:{
        type:Object,
        default:()=>{}
      },
      colors:{
        type:Array,
        default:()=>['#4CFF8F','#eff0f3']
      },
      areaColor:{
        type:Array,
        default:()=>[]
      },
      type:{
        type:String,
        default:'smooth'
      },
      showDj:{
        type:Boolean,
        default:true
      }
    },
    computed: {
      ...mapState({
        fontSize: state => state.main.fontSize,
      })
    },
    data() {
      return {
        myChart: null
      }
    },
    mounted () {
      this.init()
      const resize = ()=>{
        setTimeout(()=>{
          if(this.myChart){this.myChart.resize()}
        })
      }
      window.addEventListener('resize',resize)
      this.$once('hook:beforeDestroy',()=>{
        window.removeEventListener('resize',resize)
      })
    },
    watch:{
      lists(val){
        if(Object.keys(val).length){
          this.init()
        }
      }
    },
    methods: {
      init() {
        if(!Object.keys(this.lists).length){
          this.myChart&&this.myChart.clear()
          return
        }
        // 基于准备好的dom，初始化echarts实例
        if(!this.myChart){
          this.myChart = this.echarts.init(document.getElementById(this.id));
        }
        // 绘制图表
        let legend = Object.keys(this.lists)
        let maxLen = Object.values(this.lists).reduce((a,b)=>{
          a = Math.max(a,b.length)
          return a
        },0)+1
        const echarts = this.echarts
        let textColor = '#7BA4DD'
        let lineColor = '#1F3154'
        let option = {
          color:this.colors,
          tooltip: {
              trigger: 'axis',
          },
          legend: {
            right:10,
            textStyle:{
              color:textColor,
              fontSize:this.fontSize
              
            },
            data: legend
          },
          grid: {
            top:40,
            left:'10%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'category',
            axisTick: {
                alignWithLabel: true
            },
            axisLabel:{
              interval:4,
              fontSize:this.fontSize
            },
            axisLine:{
              lineStyle:{
                color:textColor
              }
            },
            data: Array.from({length:maxLen}, (v,k) => k)
          },
          yAxis: {
            name: '单位（个）',
            nameTextStyle:{
              fontSize:this.fontSize,
              align:"center"
            },
            type: 'value',
            axisLabel:{
              fontSize:this.fontSize,
            },
            axisLine:{
              lineStyle:{
                color:textColor
              }
            },
            splitLine: {
                lineStyle: {
                    type: "dashed",
                    color: lineColor
                }
            },
          },
          series: Object.keys(this.lists).map((v,i)=>({
            name:v,
            ...this.type==='smooth'&&{
              smooth: true,
              symbolSize: 3,
              zlevel: 3,
            },
            symbol: 'circle',
            type:'line',
            ...this.showDj&&{stack:'总量'},
            data:[0].concat(this.lists[v]),
            lineStyle:{
              type: 'linear',
              x: 1,
              y: 1,
              x2: 0,
              y2: 0,
              colorStops: [{
                  offset: 0, color: '#0098a6' // 0% 处的颜色
              }, {
                  offset: 1, color: '#29a657' // 100% 处的颜色
              }],
              global: false // 缺省为 false
            },
            areaStyle: {
              color: this.areaColor[i]
            },
          }))
      };
        this.myChart.setOption(option);
      }
    },
  }
</script>

<style lang="scss" scoped>
.linecharts{
  pointer-events: auto;
}
</style>