<template>
  <common-container  title="物联网故障/事件/报警数据统计" class="left-top" style="margin-top: 0%">
    <div class="padding height-100">
      <a-row>
        <a-col :span="12">
          <statistic title="故障总数" :value="faultCount" unit="次"></statistic>
        </a-col>
        <a-col :span="12">
          <statistic title="AI事件总数" :value="aiEventCount" unit="次"></statistic>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <statistic title="报警总数" :value="alarmCount" unit="次"></statistic>
        </a-col>
      </a-row>
    </div>
  </common-container>
</template>
<script>
  import CommonContainer from "@/views/lotBigData/components/commonContainer/Index";
  import Statistic from "../components/statistic/Index";
  import { mapState } from 'vuex'
  import {getEventrecordCountByCondition, getIotDateevent} from "@/api/wlw";
  import {getCache} from "U/index";
  export default{
    name:'LeftTop',
    components: {CommonContainer,Statistic},
    props:{
      SelectedArea:{
        default:""
      },
      SelectedPlace:{
        default:""
      },
      SelectedLiftyear:{
        default:""
      },
      SelectedDevicemodelid:{
        default:""
      },
    },
    // computed: {
    //   ...mapState({
    //     areacode: state => state.main.areacode,
    //     fontSize: state => state.main.fontSize,
    //   })
    // },
    data() {
      return {
        faultCount:'',
        aiEventCount:'',
        alarmCount:'',
        liftEventCount:'',
        iotEventCount:'',
        allData: {
          faultCount:[],//故障次数
          aiEventCount:[],//AI事件次数
          alarmCount:[],//报警次数
          liftEventCount:[],//电梯事件次数
          iotEventCount:[],//物联网终端事件次数
        },
        // timer:null
      }
    },
    mounted () {
      this.getFaultCount();
      this.getAiEventCount();
      this.getAlarmCount();
    },
    watch:{
      'SelectedArea':function (){
        this.getFaultCount();
        this.getAiEventCount();
        this.getAlarmCount();
      },
      'SelectedPlace':function (){
        this.getFaultCount();
        this.getAiEventCount();
        this.getAlarmCount();
      },
      'SelectedLiftyear':function (){
        this.getFaultCount();
        this.getAiEventCount();
        this.getAlarmCount();
      },
      'SelectedDevicemodelid':function (){
        this.getFaultCount();
        this.getAiEventCount();
        this.getAlarmCount();
      },
    },
    methods: {
      getFaultCount() {
        let logininfo = getCache('logininfo', true);
        if (logininfo) {
          this.usertype = logininfo.usertype;
          this.areacode = logininfo.areacode;
        }
        if (this.usertype == '0') {
          let params = {
            areacode: this.SelectedArea=="0"?"":this.SelectedArea,
            placeid: this.SelectedPlace=="0"?"":this.SelectedPlace,
            liftyear: this.SelectedLiftyear=="0"?"":this.SelectedLiftyear,
            terminaltypeid: this.SelectedDevicemodelid=="0"?"":this.SelectedDevicemodelid,
            eventtype: '1',
          }
          getEventrecordCountByCondition(params).then(res => {
            if (res && res.returncode == '0') {
              this.faultCount = res.count;
            }
          })
        }else{
          let params = {
            areacode: this.SelectedArea=="0"?"":this.SelectedArea,
            placeid: this.SelectedPlace=="0"?"":this.SelectedPlace,
            liftyear: this.SelectedLiftyear=="0"?"":this.SelectedLiftyear,
            terminaltypeid: this.SelectedDevicemodelid=="0"?"":this.SelectedDevicemodelid,
            eventtype: '1',
          }
          getEventrecordCountByCondition(params).then(res => {
            if (res && res.returncode == '0') {
              this.faultCount = res.count;
              // console.log(this.faultCount)
            }
          })
        }

      },
      getAiEventCount() {
        let logininfo = getCache('logininfo', true);
        if (logininfo) {
          this.usertype = logininfo.usertype;
          this.areacode = logininfo.areacode;
        }
        if (this.usertype == '0') {
          let params = {
            areacode: this.SelectedArea=="0"?"":this.SelectedArea,
            placeid: this.SelectedPlace=="0"?"":this.SelectedPlace,
            liftyear: this.SelectedLiftyear=="0"?"":this.SelectedLiftyear,
            terminaltypeid: this.SelectedDevicemodelid=="0"?"":this.SelectedDevicemodelid,
            eventtype: '3',
          }
          getEventrecordCountByCondition(params).then(res => {
            if (res && res.returncode == '0') {
              this.aiEventCount = res.count;
            }
          })
        }else{
          let params = {
            areacode: this.SelectedArea=="0"?"":this.SelectedArea,
            placeid: this.SelectedPlace=="0"?"":this.SelectedPlace,
            liftyear: this.SelectedLiftyear=="0"?"":this.SelectedLiftyear,
            terminaltypeid: this.SelectedDevicemodelid=="0"?"":this.SelectedDevicemodelid,
            eventtype: '3',
          }
          getEventrecordCountByCondition(params).then(res => {
            if (res && res.returncode == '0') {
              this.aiEventCount = res.count;
            }
          })
        }
      },
      getAlarmCount() {
        let logininfo = getCache('logininfo', true);
        if (logininfo) {
          this.usertype = logininfo.usertype;
          this.areacode = logininfo.areacode;
        }
        if (this.usertype == '0') {
          let params = {
            areacode: this.SelectedArea=="0"?"":this.SelectedArea,
            placeid: this.SelectedPlace=="0"?"":this.SelectedPlace,
            liftyear: this.SelectedLiftyear=="0"?"":this.SelectedLiftyear,
            terminaltypeid: this.SelectedDevicemodelid=="0"?"":this.SelectedDevicemodelid,
            eventtype: '2',
          }
          getEventrecordCountByCondition(params).then(res => {
            if (res && res.returncode == '0') {
              this.alarmCount = res.count;
            }
          })
        }else{
          let params = {
            areacode: this.SelectedArea=="0"?"":this.SelectedArea,
            placeid: this.SelectedPlace=="0"?"":this.SelectedPlace,
            liftyear: this.SelectedLiftyear=="0"?"":this.SelectedLiftyear,
            terminaltypeid: this.SelectedDevicemodelid=="0"?"":this.SelectedDevicemodelid,
            eventtype: '2',
          }
          getEventrecordCountByCondition(params).then(res => {
            if (res && res.returncode == '0') {
              this.alarmCount = res.count;
            }
          })
        }
      },
    }
  }
</script>
<style lang="scss" scoped>
  .left-top{
    width: 100%;
    height: 22%;
    overflow: hidden;
  }
  #sjtj{
    width: 100%;
    height: 100%;
  }
  .padding{
    padding: 8px;
    height: 100%;
  }
  .height-100{
    height: 100%;
    >.ant-row{
      height: 20%;
    }
  }
  .sp-cl{
    color: #2C9F85;
  }
</style>

<!--<template>-->
<!--  <common-container  title="智慧救援" class="left-top">-->
<!--    <div class="padding height-100">-->
<!--      <a-row>-->
<!--        <a-col :span="12">-->
<!--          <statistic title="当月解救人数" value="1344" unit="人"></statistic>-->
<!--        </a-col>-->
<!--        <a-col :span="12">-->
<!--          <statistic title="年累计" value="1344" unit="人"></statistic>-->
<!--        </a-col>-->
<!--      </a-row>-->
<!--      <a-row>-->
<!--        <a-col :span="12">-->
<!--          <statistic title="当月处置数" value="1097" unit="起"></statistic>-->
<!--        </a-col>-->
<!--        <a-col :span="12">-->
<!--          <statistic title="年累计" value="1097" unit="起"></statistic>-->
<!--        </a-col>-->
<!--      </a-row>-->
<!--      <a-row>-->
<!--        <a-col :span="12">-->
<!--          <statistic title="当月消除故障" value="271" unit="起"></statistic>-->
<!--        </a-col>-->
<!--        <a-col :span="12">-->
<!--          <statistic title="年累计" value="271" unit="起"></statistic>-->
<!--        </a-col>-->
<!--      </a-row>-->
<!--      <a-row>-->
<!--        <a-col :span="12">-->
<!--          <statistic title="社会救援站" value="257" unit="个"></statistic>-->
<!--        </a-col>-->
<!--        <a-col :span="12">-->
<!--          <statistic title="维保单位救援站数" value="662" unit="个"></statistic>-->
<!--        </a-col>-->
<!--      </a-row>-->
<!--      <a-row>-->
<!--        <a-col :span="12">-->
<!--          <statistic title="传统救援平均用时" value="14,18" unit="分,秒">-->
<!--          </statistic>-->
<!--        </a-col>-->
<!--        <a-col :span="12">-->
<!--          <statistic title="智慧救援平均用时" value="11,12" unit="分,秒">-->
<!--          </statistic>-->
<!--        </a-col>-->
<!--      </a-row>-->
<!--    </div>-->
<!--  </common-container>-->
<!--</template>-->

<!--<script>-->
<!--  import CommonContainer from "@/views/lotBigData/components/commonContainer/Index";-->
<!--  import Statistic from "../components/statistic/Index";-->
<!--  import { mapState } from 'vuex'-->
<!--  import {getEventrecordCountByCondition, getIotDateevent} from "@/api/wlw";-->
<!--  export default{-->
<!--    name:'LeftTop',-->
<!--    components: {CommonContainer,Statistic},-->
<!--    computed: {-->
<!--      ...mapState({-->
<!--        areacode: state => state.main.areacode,-->
<!--        fontSize: state => state.main.fontSize,-->
<!--      })-->
<!--    },-->
<!--    data() {-->
<!--      return {-->
<!--        faultCount:'',-->
<!--        aiEventCount:'',-->
<!--        alarmCount:'',-->
<!--        liftEventCount:'',-->
<!--        iotEventCount:'',-->
<!--        allData: {-->
<!--          // alarmCount:[],//报警次数-->
<!--          // chokeGateCount:[],//阻门事件次数-->
<!--          // trapCount:[],//困人次数-->
<!--          // batterCarCount:[],//电瓶车事件次数-->
<!--          // faultCount:[]//故障次数-->
<!--          faultCount:[],//故障次数-->
<!--          aiEventCount:[],//AI事件次数-->
<!--          alarmCount:[],//报警次数-->
<!--          liftEventCount:[],//电梯事件次数-->
<!--          iotEventCount:[],//物联网终端事件次数-->
<!--        },-->
<!--        // timer:null-->
<!--      }-->
<!--    },-->
<!--    mounted () {-->
<!--      // this.getIotDate()-->
<!--      // this.$once('hook:beforeDestroy',()=>{-->
<!--      //   clearTimeout(this.timer)-->
<!--      // })-->
<!--      this.getFaultCount();-->
<!--      this.getAiEventCount();-->
<!--      this.getAlarmCount();-->
<!--      // this.getLiftEventCount();-->
<!--      // this.getIotEventCount();-->
<!--    },-->
<!--    methods: {-->
<!--      getFaultCount() {-->
<!--        let params = {-->
<!--          eventtype: '1',-->
<!--        }-->
<!--        getEventrecordCountByCondition(params).then(res => {-->
<!--          if (res && res.returncode == '0') {-->
<!--            this.faultCount = res.count;-->
<!--          }-->
<!--        })-->
<!--      },-->
<!--      getAiEventCount() {-->
<!--        let params = {-->
<!--          eventtype: '3',-->
<!--        }-->
<!--        getEventrecordCountByCondition(params).then(res => {-->
<!--          if (res && res.returncode == '0') {-->
<!--            this.aiEventCount = res.count;-->
<!--          }-->
<!--        })-->
<!--      },-->
<!--      getAlarmCount() {-->
<!--        let params = {-->
<!--          eventtype: '2',-->
<!--        }-->
<!--        getEventrecordCountByCondition(params).then(res => {-->
<!--          if (res && res.returncode == '0') {-->
<!--            this.alarmCount = res.count;-->
<!--          }-->
<!--        })-->
<!--      },-->

<!--      // getLiftEventCount() {-->
<!--      //   let params = {-->
<!--      //     eventtype: '4',-->
<!--      //   }-->
<!--      //   getEventrecordCountByCondition(params).then(res => {-->
<!--      //     if (res && res.returncode == '0') {-->
<!--      //       this.liftEventCount = res.count;-->
<!--      //     }-->
<!--      //   })-->
<!--      // },-->
<!--      // getIotEventCount() {-->
<!--      //   let params = {-->
<!--      //     eventtype: '5',-->
<!--      //   }-->
<!--      //   getEventrecordCountByCondition(params).then(res => {-->
<!--      //     if (res && res.returncode == '0') {-->
<!--      //       this.iotEventCount = res.count;-->
<!--      //     }-->
<!--      //   })-->
<!--      // },-->
<!--    },-->
<!--    // data() {-->
<!--    //   return {-->
<!--    //     allData: {-->
<!--    //       alarmCount:[],//报警次数-->
<!--    //       chokeGateCount:[],//阻门事件次数-->
<!--    //       trapCount:[],//困人次数-->
<!--    //       batterCarCount:[],//电瓶车事件次数-->
<!--    //       faultCount:[]//故障次数-->
<!--    //     },-->
<!--    //     timer:null-->
<!--    //   }-->
<!--    // },-->
<!--    // mounted () {-->
<!--    //   // this.getIotDate()-->
<!--    //   // this.$once('hook:beforeDestroy',()=>{-->
<!--    //   //   clearTimeout(this.timer)-->
<!--    //   // })-->
<!--    // },-->
<!--    // methods: {-->
<!--    //   getIotDate() {-->
<!--    //     getIotDateevent({-->
<!--    //       areacode:this.areacode-->
<!--    //     }).then(res=>{-->
<!--    //       if(res.returncode==="0"){-->
<!--    //         this.allData = res.item-->
<!--    //       }-->
<!--    //       clearTimeout(this.timer)-->
<!--    //       this.timer = setTimeout(this.getIotDate,1000*60*5)-->
<!--    //     })-->
<!--    //   }-->
<!--    // },-->
<!--  }-->
<!--</script>-->

<!--<style lang="scss" scoped>-->
<!--  .left-top{-->
<!--    width: 100%;-->
<!--    height: 45%;-->
<!--    overflow: hidden;-->
<!--  }-->
<!--  #sjtj{-->
<!--    width: 100%;-->
<!--    height: 100%;-->
<!--  }-->
<!--  .padding{-->
<!--    padding: 8px;-->
<!--    height: 100%;-->
<!--  }-->
<!--  .height-100{-->
<!--    height: 100%;-->
<!--    >.ant-row{-->
<!--      height: 20%;-->
<!--    }-->
<!--  }-->
<!--  .sp-cl{-->
<!--    color: #2C9F85;-->
<!--  }-->
<!--</style>-->