<template>
  <common-container  title="电梯运行数据统计" class="right-top">
    <div class="top">
      <a-row>
        <a-col :span="12">
          <statistic title="总当日运行次数" :value="today_run_num" unit="次"></statistic>
        </a-col>
        <a-col :span="12">
          <statistic title="总累计运行次数" :value="accumulative_run_num" unit="次"></statistic>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <statistic title="总当日运行时间" :value="today_run_time" unit="分钟"></statistic>
        </a-col>
        <a-col :span="12">
          <statistic title="总累计运行时间" :value="accumulative_run_time" unit="小时"></statistic>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <statistic title="总当日开关门次数" :value="today_door_num" unit="次"></statistic>
        </a-col>
        <a-col :span="12">
          <statistic title="总累计开关门次数" :value="accumulative_door_num" unit="次"></statistic>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <statistic title="总当日乘梯人次" :value="today_passenger_num" unit="人"></statistic>
        </a-col>
        <a-col :span="12">
          <statistic title="总累计乘梯人次" :value="accumulative_passenger_num" unit="次"></statistic>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <statistic title="总当日运行里程" :value="today_mileage" unit="公里"></statistic>
        </a-col>
        <a-col :span="12">
          <statistic title="总累计运行里程" :value="cumulative_mileage" unit="公里"></statistic>
        </a-col>
      </a-row>
    </div>
  </common-container>
</template>

<script>
import CommonContainer from "@/views/lotBigData/components/commonContainer/Index";
import Statistic from "../components/statistic/Index";
import moment from 'moment';
import { getEventrecordListByCondition } from "@/api/wlw";
import {getCache} from "U/index";
import {getStatusDataCountListByConditon, getStatusListByConditon} from "A/bigdata";
export default{
  name:'RightTop',
  components: {CommonContainer,Statistic},
  props:{
    SelectedArea:{
      default:""
    },
    SelectedPlace:{
      default:""
    },
    SelectedLiftyear:{
      default:""
    },
    SelectedDevicemodelid:{
      default:""
    },
  },
  data() {
    return {
      areacode:'',
      accumulative_door_num:0,
      today_passenger_num:0,
      today_door_num:0,
      accumulative_passenger_num:0,
      accumulative_run_num:0,
      accumulative_run_time:0,
      today_run_num:0,
      today_run_time:0,
      today_mileage:0,
      cumulative_mileage:0,
    }
  },
  mounted () {
    this.getDatas();
  },
  watch:{
    'SelectedArea':function (){
      this.getDatas();
    },
    'SelectedPlace':function (){
      this.getDatas();
    },
    'SelectedLiftyear':function (){
      this.getDatas();
    },
    'SelectedDevicemodelid':function (){
      this.getDatas();
    },
  },
  methods: {
    getDatas() {
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.usertype = logininfo.usertype;
        this.areacode = logininfo.areacode;
      }
      let params = {
        areacode: this.SelectedArea=="0"?"":this.SelectedArea,
        placeid: this.SelectedPlace=="0"?"":this.SelectedPlace,
        liftyear: this.SelectedLiftyear=="0"?"":this.SelectedLiftyear,
        terminaltypeid: this.SelectedDevicemodelid=="0"?"":this.SelectedDevicemodelid,
      }
      getStatusDataCountListByConditon(params).then(res => {
        if (res && res.returncode == '0') {
          if(res.item[0]==null){
            this.accumulative_door_num = 0
            this.today_passenger_num = 0
            this.today_door_num = 0
            this.accumulative_passenger_num = 0
            this.accumulative_run_num = 0
            this.accumulative_run_time = 0
            this.today_run_num = 0
            this.today_run_time = 0
            this.today_mileage = 0
            this.cumulative_mileage = 0
          }else{
            this.accumulative_door_num = res.item[0].accumulative_door_num
            this.today_passenger_num = res.item[0].today_passenger_num
            this.today_door_num = res.item[0].today_door_num
            this.accumulative_passenger_num = res.item[0].accumulative_passenger_num
            this.accumulative_run_num = res.item[0].accumulative_run_num
            this.accumulative_run_time = (res.item[0].accumulative_run_time / 60).toFixed(0)
            this.today_run_num = res.item[0].today_run_num
            this.today_run_time = res.item[0].today_run_time
            this.today_mileage = res.item[0].today_mileage.toFixed(0)
            this.cumulative_mileage = res.item[0].cumulative_mileage.toFixed(0)
          }
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.right-top{
  margin-left: 5%;
  width: 100%;
  height: 40%;
  overflow: hidden;
}
.top{
  width: 100%;
  height:40%;
  display: flex;
  flex-direction: column;
  color: #9adeff;
}
.box{
  padding: 0 10px;
  line-height:36px;
  &>span:first-child{
    display: inline-block;
    width: 50%;
  }
}
.bottom{
  width: 100%;
  height: 60%;
}
@media screen and (min-height:780px){
  .top{
    font-size: 16px;
  }
  .box{
    line-height: 40px;
  }
}
</style>


<!--<template>-->
<!--  <common-container  title="公众参与" class="right-top">-->
<!--    <div class="top">-->
<!--      <div class="box">-->
<!--        <span>公众查询总次数</span>-->
<!--        <span>41974次</span>-->
<!--      </div>-->
<!--      <div class="box">-->
<!--        <span>维修处理次数</span>-->
<!--        <span>413次</span>-->
<!--      </div>-->
<!--      <div class="box">-->
<!--        <span>投诉处理次数</span>-->
<!--        <span>5674次</span>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="bottom"><pieCharts id="gzcy" :lists="datas" /></div>-->
<!--  </common-container>-->
<!--</template>-->

<!--<script>-->
<!--  import CommonContainer from "@/views/lotBigData/components/commonContainer/Index";-->
<!--  import pieCharts from "@/views/lotBigData/components/pieCharts/Index";-->
<!--  import moment from 'moment';-->
<!--  import { getEventrecordListByCondition } from "@/api/wlw";-->
<!--  export default{-->
<!--    name:'RightTop',-->
<!--    components: {CommonContainer,pieCharts},-->
<!--    data() {-->
<!--      return {-->
<!--        datas: [-->
<!--          {value: 22, name: '维保单位投诉'},-->
<!--          {value: 28, name: '业主投诉'},-->
<!--          {value: 20, name: '其他'},-->
<!--        ],-->
<!--        timer:null-->
<!--      }-->
<!--    },-->
<!--    mounted () {-->
<!--      // this.getDatas()-->
<!--      // this.$once('hook:beforeDestroy',()=>{-->
<!--      //   clearTimeout(this.timer)-->
<!--      // })-->
<!--    },-->
<!--    methods: {-->
<!--      getDatas() {-->
<!--        getEventrecordListByCondition({-->
<!--          eventtype:"2",-->
<!--          // starttime:"",-->
<!--          // endtime:"",-->
<!--          pageno:"1",-->
<!--          pagesize:"30"-->
<!--        }).then(res=>{-->
<!--          const {returncode,item} = res-->
<!--          if(returncode==="0"){-->
<!--            this.lists = item.map(v=>({-->
<!--              name:v.adress,-->
<!--              type:`${v.eventname}${v.thirdeventname?'('+v.thirdeventname+')':''}`,-->
<!--              time:moment(v.eventstarttime, 'YYYYMMDDHHmmss').format("YYYY.MM.DD HH:mm:ss")-->
<!--            }))-->
<!--          }-->
<!--          clearTimeout(this.timer)-->
<!--          this.timer = setTimeout(this.getDatas,1000*60*5)-->
<!--        })-->
<!--      }-->
<!--    },-->
<!--  }-->
<!--</script>-->

<!--<style lang="scss" scoped>-->
<!--  .right-top{-->
<!--    width: 100%;-->
<!--    height: 50%;-->
<!--  }-->
<!--  .top{-->
<!--    width: 100%;-->
<!--    height:40%;-->
<!--    display: flex;-->
<!--    flex-direction: column;-->
<!--    color: #9adeff;-->
<!--  }-->
<!--  .box{-->
<!--    padding: 0 10px;-->
<!--    line-height:36px;-->
<!--    &>span:first-child{-->
<!--      display: inline-block;-->
<!--      width: 50%;-->
<!--    }-->
<!--  }-->
<!--  .bottom{-->
<!--    width: 100%;-->
<!--    height: 60%;-->
<!--  }-->
<!--  @media screen and (min-height:780px){-->
<!--    .top{-->
<!--      font-size: 16px;-->
<!--    }-->
<!--    .box{-->
<!--      line-height: 40px;-->
<!--    }-->
<!--  }-->
<!--</style>-->