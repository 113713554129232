<template>
  <div class="base-info-contoiner">
    <a-row class="detail-form-row">
      <a-col :span="24">注册代码: <span :title="formDatas.liftnum" class="value-text">{{formDatas.liftnum}}</span></a-col>
      <a-col :span="24">使用单位: <span :title="userdepidDetail" class="value-text">{{userdepidDetail}}</span></a-col>
      <a-col :span="24">维保单位: <span :title="maintenancedepidDetail" class="value-text">{{maintenancedepidDetail}}</span></a-col>
      <a-col :span="24">上次维保: <span :title="finishtime" class="value-text">{{finishtime}}</span></a-col>
      <a-col :span="24">上次检验: <span :title="examinetime" class="value-text">{{examinetime}}</span></a-col>
      <a-col :span="24">电梯评分: <span :title="formDatas.stepnum" class="value-text">88.60分</span></a-col>
      <a-col :span="24">高峰等待时间: <span :title="formDatas.speed" class="value-text">35秒</span></a-col>
      <a-col :span="24">累计运行次数: <span :title="accumulativeNum" class="value-text">{{accumulativeNum}}</span></a-col>
    </a-row>
  </div>
</template>
<script>
import moment from 'moment'
import { getParentTreeBycode, getDictByDicType, getMentenacegroupListByCondition } from 'A/xtpz'
import { getTreeDeepString } from 'U'
import { getLiftListByCondition, getLiftinfoById, getParentTreeByid } from 'A/jcgn'
export default {
  props: ['liftid'],
  data() {
    return {
      moment,
      formDatas: {
        // 基础信息
        liftid: '',
        liftnum: '',
        liftrescue: '',
        traceabilitynum: '',
        devicecode: '',
        registerdep: '',
        registertime: '',
        registeruptime: '',
        liftcode: '',
        factorynum: '',
        factorylicense: '',
        operator: '',
        areacode: '',
        adress: '',
        adminuserdepid: '',
        coordinatex: '',
        coordinatey: '',
        status: 1,//0-未使用，1-使用
        placeid: '',
        level: '',
        // 设备信息
        devicetypeid: '',
        devicemodelid: '',
        devicemodelname: '',
        brandid: '',
        devicelicense: '',
        devicelicstarttime: '',
        devicelicendtime: '',
        devicelicenselevel: '',
        dragtype: '',
        floornum: '',
        floorcount: '',
        stepnum: '',
        doornum: '',
        speed: '',
        weight: '',
        // 设备使用标志和登记信息证件查看
        regesternum: '',
        userflagurl: '',
        userloginurl: '',
        // 使用单位信息
        userdepid: '',
        userdepid_depnum: '',
        userdepid_businessnum: '',
        userdepid_address: '',
        userdepid_liftpropertyname: '',
        userdepid_mailcode: '',
        userdepid_safeperson: '',
        userdepid_safephone: '',
        userdepid_liftpropertyid: '',
        userdeppreson: '',
        userdeppersonphone: '',
        estatemodelid: '',
        communityid: '',
        communityname: '',
        // 产权单位信息
        propertydep: '',
        propertydepnum: '',
        propertydepadr: '',
        propertydeppost: '',
        propertydepperson: '',
        propertydepadmin: '',
        propertydepphone: '',
        installtime: '',
        usetime: '',
        // 设备维保信息
        maintenancedepid: '',
        maintenancedepid_depnum: '',//维保单位代码
        maintenancedepid_certificatenum: '',//资质证书代码
        maintenancedepid_businessnum: '',//营业执照代码
        maintenancedepid_licensenum: '',//电梯安装许可证编码
        maintenancedepid_address: '',//单位地址
        maintenancedepid_rescuepreson: '',//救援人员
        maintenancedepid_rescuephone: '',//救援人员电话
        maintenancedepid_maintenanceperson1: '',//维保负责人1
        maintenancedepid_maintenancephone1: '',//维保负责人1电话
        maintenancedepid_maintenanceperson2: '',//维保负责人2
        maintenancedepid_maintenancephone2: '',//维保负责人2电话
        maintenancedepid_areamanager: '',//区域经理
        maintenancedepid_areamanagerphone: '',//区域经理电话
        maintenancedepid_depmanager: '',//单位经理
        maintenancedepid_depmanagerphone: '',//单位经理电话
        maintenancedepid_mailcode: '',//单位经理电话
        maintenancetype: '',
        maintenancegroupid: '',
        // 检验信息
        examinedepid: '',
        testdepid: '',
        rescuedepid: '',
        bigrepairtime: '',
        bigrepaircycle: '',
        // 维保合同信息
        maintenancecontacturl: '',
        maintenancecontacttime: '',
        // IP语音网关和可视通讯
        ipnetnum: '',
        videonum: '',
        // 保险信息
        insurancedep: '',
        insurancenum: '',
        insurancephone: '',
        insuranceexpiretime: '',
        // 设备安装信息
        installdep: '',
        installdepnum: '',
        installlicnum: '',
        installadminphone: '',
        installadmin: '',
        constructiondep: '',
        constructiontime: '',
        acceptdep: '',
        checkunit: '',
        checktime: '',
        checkreportnum: '',
        // 设计制造信息
        designdep: '',
        designdepnum: '',
        manufacturerid: '',
        manufacturerlic: '',
        productlic: '',
        productnum: '',
        producttime: '',
        // 审核状态
        checkstatus: '',
        // 关联设备
        deviceid: '',
      },
      areacodeDetail: '',
      adminuserdepidDetail: '',
      userdepidDetail: '',
      maintenancedepidDetail: '',
      examinedepidDetail: '',
      testdepidDetail: '',
      rescuedepidDetail: '',
      placeidDetail: '',
      devicetypeidDetail: '',
      brandidDetail: '',
      dragtypeDetail: '',
      estatemodelidDetail: '',
      maintenancetypeDetail: '',
      manufactureridDetail: '',
      maintenancegroupidDetail: '',
      examineLists: {},
      testLists: {},
      finishtime:"",
      examinetime:"",
      accumulativeNum:0
    }
  },
  watch: {
    'liftid'(val){
      if(val){
        this.initDetail();
      }
    }
  },
  created() {
    this.initDetail();
  },
  methods: {
    initDetail() {
      if(this.liftid) {
        let params = {
          liftid: this.liftid
        }
        getLiftinfoById(params).then(res => {
          if(res && res.returncode == '0') {
            for(let key in this.formDatas) {
              if(res.item[key] || res.item[key] === 0) {
                this.formDatas[key] = res.item[key];
              }
            }
            // 使用单位信息
            if(res.item.userdepLists) {
              this.userdepidDetail = res.item.userdepLists.userdepname
            }else{
              this.userdepidDetail = ''
            }
            // 维保单位信息
            if(res.item.maintenancedepLists) {
              this.maintenancedepidDetail = res.item.maintenancedepLists.userdepname
            }else{
              this.maintenancedepidDetail = ''
            }
            // 检验信息
            if(res.item.examineLists) {
              this.examineLists = res.item.examineLists;
              this.examinetime = res.item.examineLists.examinetime?moment(res.item.examineLists.examinetime, 'YYYYMMDD').format("YYYY-MM-DD"):''
            }else {
              this.examineLists = {};
              this.examinetime = ''
            }
            if(res.item.testLists) {
              this.testLists = res.item.testLists;
            }else {
              this.testLists = {};
            }
            if(res.item.maintenancerecord){
              this.finishtime = res.item.maintenancerecord.finishtime?moment(res.item.maintenancerecord.finishtime, 'YYYYMMDD').format("YYYY-MM-DD"):''
            }else{
              this.finishtime = ''
            }
            if(res.item.deviceinfos){
              const obj = res.item.deviceinfos.find(v=>v.terminalmodlenum!=="01")
              if(obj){
                this.accumulativeNum = obj.accumulative_run_num
              }else{
                this.accumulativeNum = 0
              }
            }
          }
        })
      }
    },
    getareacodeDetail(areacode) {
      let params = {
        areacode
      };
      getParentTreeBycode(params).then(res => {
        if(res && res.returncode == '0') {
          let treeStr = getTreeDeepString(res.item, '', 'areaname', 'childArea');
          this.areacodeDetail = treeStr.replace(/\|/g, '-');
        }
      })
    },
    getDeptDetail(userdepid, detailKey) {
      let params = {
        userdepid
      };
      getParentTreeByid(params).then(res => {
        if(res && res.returncode == '0') {
          let treeStr = getTreeDeepString(res.item, '', 'userdepname', 'childUserdep');
          this[detailKey] = treeStr.replace(/\|/g, '-');
        }
      })
    },
    getLiftPropertyDetail(val, liftpropertytype, detailKey) {
      let params = {
        liftpropertytype
      }
      getLiftListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          for(let i=0; i<res.item.length; i++) {
            if(res.item[i].liftpropertyid == val) {
              this[detailKey] = res.item[i].value;
              break;
            }
          }
        }
      })
    },
    getDictDetail(val, dictype, detailKey) {
      let params = {
        dictype
      }
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          for(let i=0; i<res.item.length; i++) {
            if(res.item[i].dickey == val) {
              this[detailKey] = res.item[i].dicvalue;
              break;
            }
          }
        }
      })
    },
    getMaintenancegroupDetail(val, detailKey) {
      let params = {
        maintenancedepid: this.formDatas.maintenancedepid
      }
      getMentenacegroupListByCondition(params).then(res => {
        if(res && res.returncode == '0') {
          for(let i=0; i<res.item.length; i++) {
            if(res.item[i].maintenancegroupid == val) {
              this[detailKey] = res.item[i].maintenancegroupname
              break;
            }
          }
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.base-info-contoiner {
  margin-top: 16px;
}
</style>