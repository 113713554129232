  <template>
  <common-container  title="电梯实时概况" class="middle-top">
    <a-row style="width: 100%">
      <a-col :span="8" v-if="cur===0" class="middle-top-title">电梯总量：<span>{{mainDate.liftnum}}</span></a-col>
      <a-col :span="8" v-if="cur===0" class="middle-top-title">传感器数量：<span>{{mainDate.sensornum}}</span></a-col>
      <a-col :span="8" v-if="cur===0" class="middle-top-title">控制柜数量：<span>{{mainDate.maincontrolnum}}</span></a-col>
      <a-col :span="8" v-if="cur===1" class="middle-top-title">当日处置数：<span>122</span></a-col>
      <a-col :span="8" v-if="cur===1" class="middle-top-title">当日解救人数：<span>163</span></a-col>
      <a-col :span="8" v-if="cur===1" class="middle-top-title">当前困人救援：<span>0</span></a-col>
    </a-row>
    <a-space :size="15" class="tab-size">
      <span v-for="(item,idx) in ['基本信息']" :class="{active:cur===idx}" @click="handleTabs(idx)">{{item}}</span>
    </a-space>
<!--['基本信息','实时概况']-->
    <div class="flex">
      <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form" style="width: 100%">
        <a-form-model-item label="区域" prop="areacode" class="self-select" style="width: 25%">
          <a-cascader
              @change="(val)=>handleChange(val,'areacode')"
              :field-names="{ label: 'areaname', value: 'areacode', children: 'children' }"
              :allowClear="false"
              popupClassName="self"
              :options="areaOptions"
              v-model="queryAreaSelected"
              :load-data="areaOnLoadChildren"
              placeholder="请选择区域"
              :change-on-select="true"
          >
          </a-cascader>
          <!-- <a-select style="width: 120px" defaultValue="0" @change="(val)=>handleChange(val,'areacode')" dropdownClassName="self">
            <a-select-option v-for="(item, index) in areaOptions" :key="index" :value="item.areacode">{{item.areaname}}</a-select-option>
          </a-select> -->
        </a-form-model-item>
        <a-form-model-item label="场所" prop="placeid" class="self-select" style="width: 18%" >
          <a-select style="" defaultValue="0" @change="(val)=>handleChange(val,'placeid')" dropdownClassName="self">
            <a-select-option v-for="(item, index) in placeOptions" :key="index" :value="item.liftpropertyid">{{item.value}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="梯龄" prop="liftyear" class="self-select" style="width: 18%">
          <a-select style="" defaultValue="0" @change="(val)=>handleChange(val,'liftyear')" dropdownClassName="self">
            <a-select-option value="0">全部</a-select-option>
            <a-select-option value="1">近五年</a-select-option>
            <a-select-option value="2">5到10年</a-select-option>
            <a-select-option value="3">10到15年</a-select-option>
            <a-select-option value="4">15年以上</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="类型" prop="terminaltypeid" class="self-select" style="width: 18%">
          <a-select style="" defaultValue="0" @change="(val)=>handleChange(val,'terminaltypeid')" dropdownClassName="self">
            <a-select-option v-for="(item, index) in terminaltypes" :key="index" :value="item.value">{{item.text}}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </div>
  </common-container>
</template>
<script>
  import CommonContainer from "@/views/lotBigData/components/commonContainer/Index";
  import { getItemFromArrayByKey, getTreeDeepString } from 'U'
  import { geteChildListByParentcode, getParentTreeBycode } from 'A/xtpz.js'
  import { getLiftListByCondition } from 'A/jcgn'
  import { getAreaByLift,getIotMaindata } from 'A/wlw'
  import {getTerminaltypeListByCondition} from "A/ai";
  export default{
    name:"MiddleTop",
    components: {
      CommonContainer
    },
    data() {
      return {
        selectedArea:'',
        selectedPlace:'',
        selectedLiftyear:'',
        selectedDevicemodelid:'',
        placeOptions:[],
        terminaltypes:[],
        queryParams: {},
        queryAreaSelected: [],
        areaOptions: [],
        areaCascaderSelected: [],
        cur:0,
        mainDate:{
          liftnum:0,
          maincontrolnum:0,
          sensornum:0,
          statuscount:{}
        },
      }
    },
    watch:{
      'selectedArea':function (){
        this.getIotMain();
      },
      'selectedPlace':function (){
        this.getIotMain();
      },
      'selectedLiftyear':function (){
        this.getIotMain();
      },
      'selectedDevicemodelid':function (){
        this.getIotMain();
      },
    },
    created () {
      this.getPlace()
      this.getTerminaltypes()
      this.getArea()
      this.getIotMain()
    },
    methods: {
      getIotMain(){//物联网大数据主视图
        let params={
          linkiot:1,
          areacode: this.selectedArea=="0"?"":this.selectedArea,
          placeid: this.selectedPlace=="0"?"":this.selectedPlace,
          liftyear: this.selectedLiftyear=="0"?"":this.selectedLiftyear,
          terminaltypeid: this.selectedDevicemodelid=="0"?"":this.selectedDevicemodelid,
        }
        getIotMaindata(params).then(res=>{
          if(res.returncode==="0"){
            this.mainDate = res.item
          }
        })
      },
      handleChange(val,type){
        if(type=="areacode"){
          this.selectedArea=val[val.length-1];
        }else if(type=="placeid"){
          this.selectedPlace=val;
        }else if(type=="liftyear"){
          this.selectedLiftyear=val;
        }else if(type=="terminaltypeid"){
          this.selectedDevicemodelid=val;
        }
        if(type==='areacode'){
          this.$emit('select-opt',type,val[val.length-1])
        }else{
          this.$emit('select-opt',type,val)
        }
      },
      getArea(){
        getAreaByLift({parentcode:''}).then(res=>{
          if(res && res.returncode == '0') {
            // console.log(res.item[0].areacode)
            // this.$store.commit("setAreacode",res.item[0].areacode);
            const result = res.item.map(item => {
              item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
              return item;
            });
            this.areaOptions = [{areacode:"0",areaname:'全部',isLeaf:true}].concat(result)
          }
        })
      },
      areaOnLoadChildren(selectedOptions) {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;
        this.getChildArea(targetOption.areacode);
      },
      getChildArea(parentcode) {
        let targetOption = getItemFromArrayByKey(this.areaOptions, 'areacode', parentcode);
        let params = {
          parentcode
        }
        geteChildListByParentcode(params).then(res => {
          targetOption.loading = false;
          if(res && res.returncode == '0') {
            targetOption.children = res.item.map(item => {
              item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
              return item;
            });
            this.areaOptions = [...this.areaOptions]
          }
        }).catch(err => {
          targetOption.loading = false;
        })
      },
      initAreaOptionsByAreacode(areacode) {
        let params = {
          areacode
        }
        getParentTreeBycode(params).then(res => {
          if(res && res.returncode == '0') {
            let areacodeTreeStr = getTreeDeepString(res.item, '', 'areacode', 'childArea');
            let deepAreaList = areacodeTreeStr.split('|');
            if(deepAreaList.length > 1) {
              this.initAreaListTree(deepAreaList, 0);
            }else {
              this.areaCascaderSelected = deepAreaList;
            }
          }
        })
      },
      initAreaListTree(deepAreaList, index) {
        if(index < deepAreaList.length-1) {
          let parentcode = deepAreaList[index];
          let targetOption = getItemFromArrayByKey(this.areaOptions, 'areacode', parentcode);
          let params = {
            parentcode
          }
          geteChildListByParentcode(params).then(res => {
            if(res && res.returncode == '0') {
              targetOption.children = res.item.map(item => {
                item.isLeaf = (item.areacode.length == 6 && item.areacode.substr(-2) != '00');
                return item;
              });
              // this.areaOptions = [...this.areaOptions]
              this.initAreaListTree(deepAreaList, index+1)
            }
          })
        }else {
          this.areaCascaderSelected = deepAreaList;
        }
      },
      getPlace(){
        let params = {
          liftpropertytype:4
        };
        getLiftListByCondition(params).then(res => {
          if(res && res.returncode == '0') {
            this.placeOptions = [{liftpropertyid:'0',value:'全部'}].concat(res.item);
          }
        })
      },
      getTerminaltypes() {
        getTerminaltypeListByCondition({}).then(res => {
          if(res && res.returncode == '0') {
            this.terminaltypes = res.item.map(item => {
              return {
                value: item.terminaltypeid,
                text: item.terminaltypename,
              }
            });
            this.terminaltypes = [{value:"0",text:'全部',isLeaf:true}].concat(this.terminaltypes)
          }
        })
      },
      handleTabs(idx) {
        this.cur = idx
        this.$emit('tab-click',idx)
      },
      getEquip(){
        let params = {
          liftpropertytype:2
        };
        getLiftListByCondition(params).then(res => {
          if(res && res.returncode == '0') {
            this.equipOptions = [{liftpropertyid:'0',value:'全部'}].concat(res.item);
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .middle-top{
    width: 120%;
    height: 70%;
  }
  .middle-top-title{
    color: #00d1ce;
    font-size: 18px;
    font-weight: 700;
    & >span{
      font-family: ds-digitalnormal;
      font-size: 22px;
    }
  }
  .flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #9adeff;
    font-size: 16px;
    pointer-events: auto;
  }
  .tab-size{
    pointer-events: auto;
  }
  .tab-size span{
    display: inline-block;
    background-color: rgba(1,37,77,.7);
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    padding: 5px 14px;
    &.active{
      background-color: #0060d5;
    }
  }
</style>
<style lang="scss">
  .self-select{
    .ant-select{
      width:120px;
      color: #90a5b6;
    }
    .anticon svg{
      color: #90a5b6;
    }
    .ant-select-selection,.ant-select-selection__clear,.ant-cascader-picker{
      background-color: transparent;
    }
    .ant-form-item-label > label,.ant-cascader-picker-label{
      color: #90a5b6!important;
    }
  }
  .self.ant-select-dropdown{
    background-color: transparent;
    .ant-select-dropdown-menu-item{
      color: #90a5b6;
    }
  }
  .self.ant-cascader-menus{
    background-color: transparent;
    color: #90a5b6;
  }
</style>