<template>
  <div class="flex">
    <div class="title" :style="comTitle"><a-icon :rotate="270" type="align-left" />{{title}}</div>
    <div :style="parantFont">
      {{String(value).split(',')[0]}}<span :style="childFont">{{unit.split(',')[0]}}</span>
      {{String(value).split(',')[1]}}<span :style="childFont">{{unit.split(',')[1]}}</span>
    </div>
    </slot>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default{
    name:"statistic",
    computed: {
      ...mapState({
        fontSize: state => state.main.fontSize,
      }),
      comTitle(){
        return {
          color:this.model==='super'?'#8bd9ff':'#32b999'
        }
      },
      parantFont(){
        let returnCalll = {
          color:this.model==='super'?'#47d2ff':'#23b8e8',
          fontSize:''
        }
        if(this.fontSize>14){
          returnCalll.fontSize=this.fontSize*1.5+'px'
        }else{
          returnCalll.fontSize = this.fontSize*1.2+'px'
        }
        return returnCalll
      },
      childFont(){
        if(this.fontSize>14){
          return {
            fontSize:this.fontSize*1.2+'px'
          }
        }
        return {
          fontSize:this.fontSize+'px'
        }
      }
    },
    props: {
      title: {
        type: String,
        default: ''
      },
      value: {
        type: String|Number,
        default:''
      },
      unit: {
        type: String,
      },
      model:{// super lot
        type:String,
        default:'super'
      }
    },
  }
</script>

<style lang="scss" scoped>
  .flex{
    display: flex;
    flex-direction: column;
    color: #47d2ff;
    font-size: 13px;
  }

  .title-icon{
    width: 16px;
    height: 15px;
    margin-right: 8px;
  }
</style>